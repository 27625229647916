export const COLOURS = [
    "#e53935",
    "#d81b60",
    "#8e24aa",
    "#5e35b1",
    "#3949ab",
    "#1e88e5",
    "#039be5",
    "#00acc1",
    "#00897b",
    "#43a047",
    "#7cb342",
    "#c0ca33",
    "#fdd835",
    "#ffb300",
    "#fb8c00",
    "#f4511e",
];
