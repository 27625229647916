import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
/** Store for active overlays */
const _overlays = new BehaviorSubject([]);
/** Observable for active overlay contents */
export const active_overlays = _overlays.asObservable();
/** Observable for item to draw backdrop for */
export const backdrop_element = active_overlays.pipe(map((overlay) => { var _a; return (_a = overlay.find((i) => i.backdrop)) === null || _a === void 0 ? void 0 : _a.id; }));
export function openOverlay(details, overlays = _overlays) {
    if (!details.id) {
        details.id = `overlay-${Math.floor(Math.random() * 99999999)}`;
    }
    const list = [...overlays.getValue()];
    const index = list.findIndex((item) => item.id === details.id);
    if (index >= 0) {
        list.splice(index, 1, details);
    }
    else {
        list.push(details);
    }
    overlays.next(list);
    return () => closeOverlay(details.id, overlays);
}
export function closeOverlay(id, overlays = _overlays) {
    const list = [...overlays.getValue()];
    const item = list.find(item => item.id === id);
    overlays.next(list.filter((item) => item.id !== id));
    if (item && item.onClose) {
        item.onClose();
    }
}
